import React from 'react';
import IconArrow from '../../images/svg/arrow-forward.svg';
import IconArrowBack from '../../images/svg/arrow-back.svg';
import { colors } from '../primitives/colors';
import useLocalStorageState from '../../hooks/use-local-storage-state';
import Typography from '../primitives/typography';
import { breakpoints } from '../primitives/tokens';
import Button from '../primitives/button';
import { useLocator } from '../../contexts/franchise/locator-provider';
import Container from '../primitives/grid/container';
import CloseButton from '../atoms/CloseButton';
import { StyledButtonAsLink } from '../primitives/styled-button';

const LOCAL_STORAGE_KEY = 'servpro-announcement-closed';

interface CallToAction {
    title: string
    href: string
}

interface SingleAnnouncementProps {
    id: string,
    title: string | null,
    copy: string | null,
    enable: boolean
    call_to_action: CallToAction

}

const SingleAnnouncement = ({
    id,
    title,
    copy,
    enable,
    call_to_action: callToAction
}: SingleAnnouncementProps): JSX.Element | null => {

    const { title: ctaTitle, href: ctaHref } = callToAction;

    if (enable === false) {
        return null;
    }

    const {
        data: isClosed,
        setData: setIsClosed,
        loading,
    } = useLocalStorageState<boolean | null>(
        `${LOCAL_STORAGE_KEY}-${id}`,
        async () => false,
        [],
        36,
        () => true,
    );

    const [expanded, setExpanded] = React.useState(false);

    if (isClosed || loading) return null;

    return (
        <div css={{
            backgroundColor: colors.statesError,
            color: colors.shadesWhite,
            padding: '20px 0',
        }}
        >
            <Container css={{
                display: 'flex',
                alignItems: 'flex-start',
            }}
            >
                <div css={{
                    width: '100%',
                }}
                >
                    <Typography fontSize="xl" fontWeight="medium">{title}</Typography>
                    <Typography
                        css={{
                            fontSize: '1rem',
                            p: {
                                marginBottom: '0.4rem',
                            },
                            a: {
                                fontWeight: 'bold',
                                textDecoration: 'underline',
                            },
                            [breakpoints.mb]: {
                                display: 'none',
                            },
                        }}
                        fontSize="sm"
                        dangerouslySetInnerHTML={{
                            __html: copy || '',
                        }}
                    />
                    {ctaHref && ctaTitle && (<StyledButtonAsLink
                        css={{
                            marginTop: '10px', marginBottom: '10px', [breakpoints.mb]: {
                                display: 'none',
                            },
                        }} 
                        to={ctaHref}>
                            {ctaTitle}
                        </StyledButtonAsLink>)
                    }
                    <div style={{
                        display: expanded ? 'block' : 'none',
                    }}
                    >
                        <Typography
                            css={{
                                fontSize: '1rem',
                                p: {
                                    marginBottom: '0.4rem',
                                },
                                a: {
                                    fontWeight: 'bold',
                                    textDecoration: 'underline',
                                },
                                display: 'none',
                                [breakpoints.mb]: {
                                    display: 'block',
                                },
                            }}
                            fontSize="sm"
                            dangerouslySetInnerHTML={{
                                __html: copy || '',
                            }}
                        />
                        {ctaHref && ctaTitle && (<StyledButtonAsLink css={{ marginTop: '10px', marginBottom: '10px' }} to={ctaHref}>{ctaTitle}</StyledButtonAsLink>)}
                    </div>
                    <Button
                        css={{
                            display: 'none',
                            [breakpoints.mb]: {
                                display: 'flex',
                            },
                        }}
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                    >
                        {expanded && (
                            <div css={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '4px',
                            }}
                            >
                                <IconArrowBack css={{ marginRight: '4px' }} />
                                <span>Show Less</span>
                            </div>
                        )}
                        {!expanded && (
                            <div css={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '4px',
                            }}
                            >
                                <span>Read More</span>
                                <IconArrow css={{ marginLeft: '4px' }} />
                            </div>
                        )}
                    </Button>
                </div>
                <CloseButton
                    css={{
                        position: 'relative',
                        color: colors.shadesWhite,
                    }}
                    onClick={() => {
                        setIsClosed(true);
                    }}
                />

            </Container>
        </div>
    );
};

const AnnouncementBar = ({ franchise }): JSX.Element | null => {
    const { geo, loading: geoLoading, announcements } = useLocator();
    // comes from franchise only pages
    const { relevantAnnouncements: franchiseLevelAnnouncements } = franchise || {};

    if (franchiseLevelAnnouncements || announcements) {
        return (franchiseLevelAnnouncements || announcements).map(x => (
            <SingleAnnouncement
                id={x.uid}
                {...x}
            />
        ));
    }
    return null;
};

export default AnnouncementBar;
